import { decode } from 'jsonwebtoken';
/**
 * TokenStorageService manages SwipeRxAccount instances stored in window.localStorage
 *
 * @typedef {Object} SwiperxCredentials
 * @property {string} userId - swiperx userid
 * @property {string} accessToken - JWT token sent to core api
 * @property {string} refreshToken - JWT token sent to core api
 */
export class TokenStorage {
  constructor() {
    this.storeKeys = {
      accounts: 'swrx:accounts',
      activeAccountId: 'swrx:activeAccountId',
    };
    this.storeProvider = window.localStorage;
  }

  /**
   * @returns {String}
   */
  getActiveAccountId() {
    return (
      this.storeProvider.getItem(this.storeKeys.activeAccountId) || 'default'
    );
  }

  /**
   * @returns {{[index: string]: SwiperxCredentials}}
   */
  getAccounts() {
    return (
      JSON.parse(this.storeProvider.getItem(this.storeKeys.accounts)) || {}
    );
  }

  /**
   * @returns {SwiperxCredentials} accessToken and refreshToken
   */
  getAccountCredentials() {
    const tokens = this.getAccounts()[this.getActiveAccountId()];

    return tokens;
  }

  /**
   *
   * @param {SwiperxCredentials} swiperxCredentials
   */
  setAccountCredentials(swiperxCredentials) {
    const accounts = this.getAccounts();
    const activeAccountId =
      swiperxCredentials.userId || this.getActiveAccountId();
    const account = accounts[activeAccountId];

    this.storeProvider.setItem(
      this.storeKeys.accounts,
      JSON.stringify({
        ...accounts,
        [activeAccountId]: {
          ...account,
          ...swiperxCredentials,
        },
      })
    );
    this.storeProvider.setItem(this.storeKeys.activeAccountId, activeAccountId);
  }

  /**
   * Set activeAccountId in localStorage
   *
   * @param {string} email
   */
  setActiveAccountId(email) {
    this.storeProvider.setItem(this.storeKeys.activeAccountId, email);
  }

  isAccountCredentialsValid() {
    const credentials = this.getAccountCredentials();
    if (!credentials.accessToken) {
      return false;
    }

    const decodedJwt = decode(credentials.accessToken);
    return decodedJwt.exp * 1000 > Date.now();
  }

  /**
   * @returns {TokenStorage} token storage instance
   */
  static getInstance() {
    if (!TokenStorage._instance) {
      TokenStorage._instance = new TokenStorage();
    }

    return TokenStorage._instance;
  }
}
