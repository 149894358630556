import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { API } from '../api';
import { Button, Loader, Modal } from '../components/common';
import { Action, useStateContext } from '../providers/StateContext';
import { routes } from '../routes';
import { Image } from '../assets/images';
import useFnWrapper from '../hooks/useFnWrapper';

export const Screen = (props) => {
  const [{ form }, dispatch] = useStateContext();

  const [symptoms, setSymptoms] = useState([]);
  const [selected, setSelected] = useState([]);
  const [hasSymptoms, setHasSymptoms] = useState(false);
  const [symptomsLoading, setSymptomsLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [getSymptoms] = useFnWrapper(API.getSymptoms);
  const [createScreenedReferral] = useFnWrapper(API.createScreenedReferral);

  useEffect(() => {
    getSymptoms().then((res) => {
      setSymptoms(res.data ? res.data : []);
      setSymptomsLoading(false);
    });

    dispatch({
      type: Action.SET_NAME,
      name: 'Identifikasi gejala',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setHasSymptoms(selected.filter((s) => s.val).length ?? true);
    dispatch({
      type: Action.SET_SCREENING,
      screening: selected,
    });
  }, [dispatch, selected]);

  useEffect(() => {
    setSelected(symptoms.map((symptom) => ({ id: symptom.id, val: false })));
  }, [symptoms]);

  const tick = (id) => {
    setSelected(
      selected.map((obj) => {
        return id === obj.id ? { id: obj.id, val: !obj.val } : obj;
      })
    );
  };

  const refer = () => {
    dispatch({
      type: Action.SET_FORM,
      form: {
        ...form,
        symptoms: selected
          .filter((symptom) => symptom.val)
          .map((symptom) => symptom.id),
      },
    });

    props.history.push(routes.refer.path);
  };

  const done = async () => {
    const selectedSymptoms = Object.values(selected)
      .filter((cb) => cb.val)
      .map((cb) => cb.id);
    const result = await createScreenedReferral(selectedSymptoms);

    if (result && !(result instanceof Error))
      props.history.push(routes.end.path);
  };

  return (
    <React.Fragment>
      <div className="screening-form">
        <Form>
          <h4 className="form-title">
            <b>Gejala utama</b>
          </h4>
          <div className="checkbox-list">
            <Loader loading={symptomsLoading} />
            {symptoms.map((symptom) => (
              <div
                key={`symptom-${symptom.id}`}
                className={`checkbox-group ${
                  selected.find((cb) => cb.id === symptom.id && cb.val)
                    ? 'checked'
                    : ''
                }`}
              >
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value={symptom.id}
                      onClick={() => tick(symptom.id)}
                    />
                    <img
                      className="symptom-icon"
                      src={symptom.thumbnail_url}
                      alt="tb-symptom"
                    />
                    <p>{symptom.description}</p>
                    <div className="checkbox-check">
                      <img src={Image.Check.src} alt={Image.Check.alt} />
                    </div>
                  </Label>
                </FormGroup>
              </div>
            ))}
          </div>
          <div className={'button-list'}>
            <Button
              classes={'primary'}
              disabled={!hasSymptoms}
              label="Rujuk klien"
              onClick={() => setShowConfirmationModal(true)}
            />
            <Button
              classes={'secondary'}
              label="Selesai"
              disabled={!hasSymptoms}
              onClick={() => setShowDoneModal(true)}
            />
          </div>
        </Form>
      </div>
      <Modal showModal={showConfirmationModal}>
        <div className="screening-confirm">
          <div className="content">
            <img src={Image.DataSave.src} alt={Image.DataSave.alt} />
            <p>
              Data berhasil disimpan, klien Anda memiliki{' '}
              {selected.filter((o) => o.val).length} dari 10 gejala. Yuk,
              lanjutkan proses rujukan agar klien segera tertangani.
            </p>
          </div>
          <div className="selected-symptoms">
            {symptoms.map((symptom) => (
              <div
                key={`symptom-list-${symptom.id}`}
                className={`symptom ${
                  selected.find((cb) => cb.id === symptom.id && cb.val)
                    ? 'selected'
                    : ''
                }`}
              >
                <img
                  className="symptom-icon"
                  src={symptom.thumbnail_url}
                  alt="tb-symptom"
                />
                <p>{symptom.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={'button-list'}>
          <Button
            classes={'primary'}
            label="Isi formulir"
            onClick={() => refer()}
          />
          <Button
            classes={'primary'}
            label="Ubah gejala"
            onClick={() => setShowConfirmationModal(false)}
          />
        </div>
      </Modal>
      <Modal showModal={showDoneModal}>
        <div className="screening-done">
          <div className="content">
            <img src={Image.ScreeningDone.src} alt={Image.ScreeningDone.alt} />
            <p>
              Klien Anda menunjukkan {selected.filter((cb) => cb.val).length}{' '}
              dari 10 gejala. Apakah Anda akan merujuk klien?
            </p>
          </div>
          <div className="selected-symptoms">
            {symptoms.map((symptom) => (
              <div
                key={`symptom-list-${symptom.id}`}
                className={`symptom ${
                  selected.find((cb) => cb.id === symptom.id && cb.val)
                    ? 'selected'
                    : ''
                }`}
              >
                <img
                  className="symptom-icon"
                  src={symptom.thumbnail_url}
                  alt="tb-symptom"
                />
                <p>{symptom.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={'button-list'}>
          <Button
            classes={'primary'}
            label="Rujuk klien"
            onClick={() => {
              setShowDoneModal(false);
              setShowConfirmationModal(true);
              return;
            }}
          />
          <Button
            classes={'primary'}
            label="Ubah gejala"
            onClick={() => setShowDoneModal(false)}
          />
          <Button classes={'secondary'} label="Tidak" onClick={() => done()} />
        </div>
      </Modal>
      <div className="end-border"></div>
    </React.Fragment>
  );
};
