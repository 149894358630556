import React from 'react';
import { Select } from '../common';
import {
  Form as StrapForm,
  FormGroup as StrapFormGroup,
  Label,
  Input,
} from 'reactstrap';
import { genderTypes } from '../../api/types';

const FormGroup = (props) => {
  return (
    <StrapFormGroup className={props.className}>
      {props.children}
      <span className="error-message">{props.errorMessage}</span>
    </StrapFormGroup>
  );
};

const ConfirmationForm = (props) => {
  const { subscriberData, onAgree } = props;
  return (
    <div className="confirmation-form">
      <div className="content">
        <h4 className="form-title">
          <b>Konfirmasi</b>
        </h4>
        <p>
          Berikut adalah data klien yang <br />
          akan dirujuk
        </p>
      </div>
      <StrapForm>
        <FormGroup>
          <Label>HP</Label>
          <p> {subscriberData.mobile || ''}</p>
        </FormGroup>
        {subscriberData.age && (
          <FormGroup>
            <Label>Umur</Label>
            <p> {subscriberData.age || ''} tahun</p>
          </FormGroup>
        )}
        {subscriberData.sex.name && (
          <FormGroup>
            <Label>Jenis kelamin</Label>
            <p> {subscriberData.sex.name || ''}</p>
          </FormGroup>
        )}
        <FormGroup>
          <Label>Kota</Label>
          <p> {'Kota Medan'}</p>
        </FormGroup>
        <FormGroup>
          <Label>Fasilitas rujukan</Label>
          <p>
            {subscriberData.facility?.name}
            <span>{subscriberData.facilityAddress ?? ''}</span>
          </p>
        </FormGroup>
      </StrapForm>
      <div className="terms">
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onClick={() => {
                onAgree();
              }}
            />
            Saya, sebagai tenaga kefarmasian, dengan ini menyatakan bahwa data
            ini benar dan saya telah menerima persetujuan dari klien. Data ini
            hanya akan digunakan untuk keperluan rujukan.
          </Label>
        </FormGroup>
      </div>
    </div>
  );
};

const ReferralForm = (
  subscriberData,
  options,
  onChangeHandler,
  form,
  forceEnable,
  errorMessages = {}
) => {
  const { facilities } = options;
  return (
    <StrapForm>
      <FormGroup
        errorMessage={errorMessages?.mobile}
        className={`${errorMessages?.mobile ? 'error' : ''}`}
      >
        <Label for="mobile">Nomor HP*</Label>
        <Input
          name="mobile"
          type="text"
          id="mobile"
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
          value={subscriberData.mobile}
          disabled={forceEnable ? !forceEnable : !!form.mobile}
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.age}
        className={`${errorMessages?.age ? 'error' : ''}`}
      >
        <Label for="age">Umur*</Label>
        <Input
          name="age"
          type="text"
          id="age"
          onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
          value={subscriberData.age}
          disabled={forceEnable ? !forceEnable : !!form.age}
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.gender}
        className={`${errorMessages?.gender ? 'error' : ''}`}
      >
        <Label for="sex">Jenis kelamin*</Label>
        <Select
          placeholder=" "
          onChangeHandler={onChangeHandler}
          default={0}
          name="sex"
          id="sex"
          value={subscriberData.sex.value}
          options={genderTypes}
          disabled={forceEnable ? !forceEnable : !!form.sex?.value}
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.municipality}
        className={`${errorMessages?.municipality ? 'error' : ''}`}
      >
        <Label for="mobile">
          Kota*
          <span>Isi sesuai lokasi apotek</span>
        </Label>
        <Select
          onChangeHandler={onChangeHandler}
          default={0}
          name="municipality"
          id="municipality"
          value={subscriberData.municipality.value}
          options={[
            {
              name: 'Kota Medan',
              value: 'Kota Medan',
            },
          ]}
          disabled={forceEnable ? !forceEnable : !!form.municipality?.value}
          placeholder=" "
        />
      </FormGroup>
      <FormGroup
        errorMessage={errorMessages?.facility}
        className={`${errorMessages?.facility ? 'error' : ''}`}
      >
        <Label for="mobile">
          Fasilitas rujukan*
          <span>Isi sesuai pilihan klien</span>
        </Label>
        <Select
          onChangeHandler={onChangeHandler}
          default={0}
          name="facility"
          id="facility"
          value={subscriberData.facility.id}
          options={
            facilities instanceof Error
              ? []
              : facilities.map((facility) => ({
                  name: facility.name,
                  value: facility.id,
                }))
          }
          search={true}
          disabled={forceEnable ? !forceEnable : !!form.facility?.value}
          placeholder=" "
        />
      </FormGroup>
    </StrapForm>
  );
};

const UpdateForm = (props) => {
  const { newData, subscriberData, onChangeHandler, errorMessages } = props;
  return (
    <div className="update-form">
      <StrapForm>
        <FormGroup>
          <Label>HP</Label>
          <p>
            {' '}
            {(subscriberData.mobile &&
              `+62${subscriberData.mobile.slice(1)}`) ||
              ''}
          </p>
        </FormGroup>
        <FormGroup
          errorMessage={errorMessages?.age}
          className={`${errorMessages?.age ? 'error' : ''}`}
        >
          <Label className={`${!subscriberData.age ? 'no-val' : ''}`}>
            Umur
          </Label>
          {subscriberData.age ? (
            <p> {subscriberData.age || ''} tahun</p>
          ) : (
            <Input
              name="age"
              type="text"
              id="age"
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              value={subscriberData.age || newData.age}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Label className={`${!subscriberData.sex.name ? 'no-val' : ''}`}>
            Jenis kelamin
          </Label>
          {subscriberData.sex.name ? (
            <p> {subscriberData.sex.name || ''}</p>
          ) : (
            <Select
              onChangeHandler={onChangeHandler}
              default={0}
              name="sex"
              id="sex"
              value={newData.sex.value || subscriberData.sex.value}
              options={genderTypes}
              placeholder=" "
            />
          )}
        </FormGroup>

        <FormGroup>
          <Label>Kota</Label>
          <p> {'Kota Medan'}</p>
        </FormGroup>
        <FormGroup>
          <Label>Fasilitas rujukan</Label>
          <p>
            {subscriberData.facility?.name}
            <span>{subscriberData.facility?.address ?? ''}</span>
          </p>
        </FormGroup>
      </StrapForm>
    </div>
  );
};

export const Form = {
  ConfirmationForm,
  ReferralForm,
  UpdateForm,
};
