import React, { useEffect } from 'react';
import { Button } from '../components/common';
import { routes } from '../routes';
import { Action, useStateContext } from '../providers/StateContext';
import { Image } from '../assets/images';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const Home = (props) => {
  const [{ form, isAuthorized }, dispatch] = useStateContext();

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: 'Program Rujukan Batuk',
    });

    dispatch({
      type: Action.SET_FILTERS,
      filters: {
        startDate: new Date(
          dayjs().hour(1).minute(0).second(0).subtract(1, 'month').format()
        ),
        endDate: new Date(dayjs().hour(23).minute(59).second(0)),
        status: '',
      },
    });
  }, [dispatch]);

  const refer = () => {
    dispatch({
      type: Action.SET_FORM,
      form: {
        ...form,
        symptoms: [],
        mobile: '',
        sex: {
          name: '',
          value: null,
        },
        age: '',
        municipality: {
          name: '',
          value: null,
        },
        facility: {
          name: '',
          value: null,
        },
      },
    });

    props.history.push(routes.screen.path);
  };

  const goTo = (path) => {
    props.history.push(path);
  };

  return (
    <React.Fragment>
      <div>
        <div className="tbps-banner">
          <img src={Image.Banner.src} alt={Image.Banner.alt} />
        </div>
        <div className={'home'}>
          <div className="content">
            <p className="first-line">Tentang program ini</p>
            <p>
              Tuberkulosis adalah penyakit menular yang disebabkan oleh kuman{' '}
              <i>Mycobacterium tuberculosis</i>, dan biasa dikenal dengan
              sebutan TBC. Sebagian besar pasien TBC mencari pengobatan awal di
              apotek pada saat mengalami gejala awal seperti batuk
              terus-menerus, baik berdahak maupun tidak berdahak.
              <br />
              <br />
              Program rujukan online ini bertujuan untuk memfasilitasi tenaga
              kefarmasian di apotek untuk dapat mengidentifikasi gejala-gejala
              TBC serta merujuk klien tersebut ke fasilitas kesehatan untuk
              mendapatkan pemeriksaan lebih lanjut dan perawatan yang
              diperlukan.
              <br />
            </p>
            <p className="second-line">Alur penggunaan</p>
            <img src={Image.Steps.src} alt={Image.Steps.alt} />
          </div>
          {isAuthorized && (
            <div className="button-list">
              <Button
                classes="secondary"
                label="Identifikasi gejala"
                onClick={() => refer()}
              />
              <Button
                classes="primary"
                label="Daftar rujukan saya"
                onClick={() => goTo(routes.list.path)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="end-border"></div>
    </React.Fragment>
  );
};
