import { TokenStorage } from './tokens/TokenStorage';
import { TokenProvider } from './tokens/TokenProvider';
import { CoreHttp } from './http/CoreHttp';
import { RtHttp } from './http/RtHttp';
import { authFactory } from './auth';

/**
 * @typedef {Object} ServiceContainer
 * @property {import('./tokens/TokenStorage').TokenStorage} tokenStorage
 * @property {import('./tokens/TokenProvider').TokenProvider} tokenProvider
 * @property {import('./http/RtHttp').RtHttp} rtHttp
 * @property {import('./http/CoreHttp').CoreHttp} coreHttp
 */

/**
 * @type {ServiceContainer}
 */
export let serviceContainer;

export const initializeServices = async () => {
  serviceContainer = window.swrx = window.swrx || {};

  serviceContainer.tokenStorage = TokenStorage.getInstance();
  serviceContainer.coreHttp = await CoreHttp.createCoreHttp(
    serviceContainer.tokenStorage
  );
  serviceContainer.rtHttp = await RtHttp.createRtHttp(
    serviceContainer.tokenStorage
  );
  serviceContainer.tokenProvider = TokenProvider.createTokenProvider(
    TokenProvider.detectPlatform(),
    new TokenProvider(serviceContainer.tokenStorage),
    serviceContainer.coreHttp
  );
  serviceContainer.auth = authFactory(serviceContainer);
};
