import has from 'lodash/has';
import { fromEvent, TimeoutError, firstValueFrom } from 'rxjs';
import { map, timeout, take, catchError } from 'rxjs/operators';
import { decode } from 'jsonwebtoken';

/** TokenProvider (Abstract class)
 *
 * 1. Listens to DOM events, to get access token
 * 1. eventHandlers:
 * ```
 * onNewAccessToken -> TokenStorage.setAccessToken()
 * onAccessTokenExpired -> TokenProvider.refreshToken() -> TokenStorage.setAccessToken()
 * ```
 *
 * Provides access token from 3 platforms:
 * 1. ios
 * 2. android
 * 3. web
 *
 * @property {TokenStorage} tokenStorage - store access token
 *
 * @typedef {import('./TokenProvider').TokenProvider} TokenProvider
 * @typedef {import('./TokenStorage').TokenStorage} TokenStorage
 * @typedef {import('../http/CoreHttp').CoreHttp} CoreHttp
 */
export class TokenProvider {
  /**
   * TokenProviderPlatforms
   * @readonly
   * @enum {string}
   */
  static platforms = {
    Android: 'android',
    Ios: 'iOS',
    Web: 'web',
  };

  static events = {
    AccessTokenChange: 'accesstokenchange',
  };

  static errors = {
    TimedOut: 'fetch access token timeout',
    NoAvailableManager: 'Unable to find an access token manager',
  };

  platform = '';

  /**
   * @param {TokenStorage} tokenStorage - access token storage
   */
  constructor(tokenStorage) {
    this.tokenStorage = tokenStorage;
  }

  /**
   * Call fetch access token from platform and captures dom event (`TokenProvider.events.AccessTokenChange`), and returns JWT token as Promise
   *
   * @returns {Promise<string>} JWT token
   */
  async fetchAccessToken() {
    return this.fetchCredentials().then(
      (credentials) => credentials.accessToken
    );
  }

  /**
   * Call fetch access token from platform and captures dom event (`TokenProvider.events.AccessTokenChange`), and returns JWT token as Promise
   *
   * @returns {Promise<import('./TokenStorage').SwiperxCredentials>} credentials
   */
  fetchCredentials() {}

  /**
   * Calls refresh token for platform and captures dom event (`TokenProvider.events.AccessTokenChange`), and returns *new* JWT token as Promise
   *
   * @returns {Promise<import('./TokenStorage').SwiperxCredentials>} credentials
   */
  refreshToken() {}

  /**
   * Capture dom event (`TokenProvider.events.AccessTokenChange`), and returns *new* JWT token as Promise
   *
   * @returns {Promise<import('./TokenStorage').SwiperxCredentials>} JWT token
   */
  async handleAccessTokenChange() {
    const jwt = await firstValueFrom(
      fromEvent(document, TokenProvider.events.AccessTokenChange).pipe(
        take(1),
        timeout(this.timeout || 1000),
        catchError((err) => {
          if (err instanceof TimeoutError) {
            throw new Error(TokenProvider.errors.TimedOut);
          }

          throw err;
        }),
        map(({ detail }) => detail.accessToken)
      ),
      { defaultValue: null }
    );

    const decodedJwt = decode(jwt);
    const credentials = {
      accessToken: jwt,
      userId: decodedJwt.sub,
    };
    this.tokenStorage.setActiveAccountId(decodedJwt.sub);
    this.tokenStorage.setAccountCredentials(credentials);
    this.setRefreshTimer();

    return credentials;
  }

  /**
   * Set refresh timer using setTimeout
   */
  setRefreshTimer() {
    if (this.tokenExpiryTimeoutId) {
      clearTimeout(this.tokenExpiryTimeoutId);
    }

    const decodedToken = decode(
      this.tokenStorage.getAccountCredentials().accessToken
    );
    this.tokenExpiryTimeoutId = setTimeout(
      () => this.refreshToken(),
      decodedToken.exp * 1000 - Date.now()
    );
  }

  /**
   * @returns {TokenProviderPlatforms}
   */
  static detectPlatform() {
    if (has(window, 'webkit.messageHandlers.swiperx')) {
      return TokenProvider.platforms.Ios;
    }

    if (has(window, 'accessTokenBridge')) {
      return TokenProvider.platforms.Android;
    }

    return TokenProvider.platforms.Web;
  }

  /**
   * Create TokenProvider
   *
   * @param {TokenProviderPlatforms} platform - platform selector
   * @param {TokenProvider} tokenProvider - token provider abstract object / template
   * @param {CoreHttp} coreHttp - use by web token provider
   *
   * @returns {TokenProvider} token provider instance
   */
  static createTokenProvider(
    platform,
    tokenProvider,
    coreHttp,
    serviceContainer = window
  ) {
    const tokenProviderFactoryMap = {
      [TokenProvider.platforms.Web]: () =>
        TokenProvider.createWebTokenProvider(tokenProvider, coreHttp),
      [TokenProvider.platforms.Ios]: () =>
        TokenProvider.createIosTokenProvider(tokenProvider, serviceContainer),
      [TokenProvider.platforms.Android]: () =>
        TokenProvider.createAndroidTokenProvider(
          tokenProvider,
          serviceContainer
        ),
    };
    tokenProvider.platform = platform;

    return tokenProviderFactoryMap[platform]();
  }

  /**
   * @param {TokenProvider} tokenProvider - abstract TokenProvider object
   * @param {CoreHttp} coreHttp - fetch refresh token
   *
   * @returns {TokenProvider}
   */
  static createWebTokenProvider(tokenProvider, coreHttp) {
    tokenProvider.fetchCredentials = () => {
      tokenProvider.setRefreshTimer();
      return Promise.resolve(
        tokenProvider.tokenStorage.getAccountCredentials()
      );
    };
    tokenProvider.refreshToken = async () => {
      const refreshToken = tokenProvider.tokenStorage.getAccountCredentials()
        .refreshToken;
      const refreshTokenResponse = await coreHttp.httpClient.post(
        '/auth/token',
        {
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
        }
      );

      const decodedToken = decode(refreshTokenResponse.data.access_token);
      tokenProvider.tokenStorage.setAccountCredentials({
        userId: String(decodedToken.sub),
        refreshToken: refreshTokenResponse.data.refresh_token || refreshToken,
        accessToken: refreshTokenResponse.data.access_token,
      });

      return tokenProvider.fetchCredentials();
    };

    return tokenProvider;
  }

  /**
   * @param {TokenProvider} tokenProvider - abstract TokenProvider object
   *
   * @returns {TokenProvider}
   */
  static createIosTokenProvider(tokenProvider, serviceContainer = window) {
    tokenProvider.fetchCredentials = () => {
      serviceContainer.webkit.messageHandlers.swiperx.postMessage(
        'fetchAccessToken'
      );
      return tokenProvider.handleAccessTokenChange();
    };
    tokenProvider.refreshToken = () => {
      serviceContainer.webkit.messageHandlers.swiperx.postMessage(
        'refreshAccessToken'
      );
      return tokenProvider.handleAccessTokenChange();
    };

    return tokenProvider;
  }

  /**
   * @param {TokenProvider} tokenProvider - abstract TokenProvider object
   *
   * @returns {TokenProvider}
   */
  static createAndroidTokenProvider(tokenProvider, serviceContainer = window) {
    tokenProvider.fetchCredentials = () => {
      serviceContainer.fetchAccessToken();
      return tokenProvider.handleAccessTokenChange();
    };
    tokenProvider.refreshToken = () => {
      serviceContainer.refreshToken();
      return tokenProvider.handleAccessTokenChange();
    };

    return tokenProvider;
  }
}
