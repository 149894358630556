/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import React, { useState, useEffect } from 'react';
import { Button, Modal } from '../components/common';
import { referralSchema } from '../validation';
import { Action, useStateContext } from '../providers/StateContext';
import { API } from '../api';
import { Form } from '../components/forms';
import { routes } from '../routes';
import {
  MobileNumberException,
  AgeException,
  MunicipalityException,
  FacilityException,
  GenderException,
} from '../api/CustomExceptions';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { attempt, isError } from 'lodash';
import useFnWrapper from '../hooks/useFnWrapper';

export const Refer = (props) => {
  const [{ form }, dispatch] = useStateContext();

  const [facilities, setFacilities] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [forceEnable, setForceEnable] = useState(false);
  const [termAgreed, setTermAgreed] = useState(false);
  const [subscriberData, setSubscriberData] = useState({
    name: form.name,
    mobile: form?.mobile || '',
    sex: form.sex || {
      name: '',
      value: null,
    },
    age: form.age || '',
    municipality: form.municipality || {
      name: '',
      value: null,
    },
    facility: form.facility || {
      name: '',
      value: null,
    },
    referralId: form.referralId,
  });

  const [getDistricts] = useFnWrapper(API.getDistricts);
  const [getFacilities] = useFnWrapper(API.getFacilities);
  const [findSubscribers] = useFnWrapper(API.findSubscribers);
  const [createReferral] = useFnWrapper(API.createReferral);

  useEffect(() => {
    getDistricts().then((res) => {
      setMunicipalities(res.items);
    });

    dispatch({
      type: Action.SET_NAME,
      name: 'Rujuk klien',
    });
  }, [dispatch]);

  useEffect(() => {
    getFacilities(subscriberData.municipality?.value).then((res) => {
      setFacilities(res);
    });
  }, [subscriberData.municipality]);

  const submit = async () => {
    try {
      const errors = [];
      const { mobile, age, sex, facility, municipality } = subscriberData;
      const { error } = referralSchema.validate(
        {
          mobile_number: mobile,
          age: age.length ? age : null,
          sex: sex.value,
          facility: facility.name,
          municipality: municipality.name,
        },
        { abortEarly: false }
      );

      if (error) {
        error.details.forEach((err) => {
          if (err.message.indexOf('mobile_number') > 0) {
            if (
              err.message.indexOf('required') > 0 ||
              err.message.indexOf('empty') > 0
            ) {
              errors.push(new MobileNumberException('*wajib diisi'));
              return;
            }

            if (err.message.indexOf('length') > 0) {
              errors.push(new MobileNumberException('panjang nomor harus 11'));
              return;
            }
          }

          if (err.message.indexOf('age') > 0) {
            if (
              err.message.indexOf('required') > 0 ||
              (err.message.indexOf('number') > 0 && !age)
            ) {
              errors.push(new AgeException('*wajib diisi'));
              return;
            }
            if (
              err.message.indexOf('99') > 0 ||
              err.message.indexOf('1') > 0 ||
              err.message.indexOf('number') > 0
            ) {
              errors.push(new AgeException('usia harus angka yang valid'));
              return;
            }
          }

          if (err.message.indexOf('municipality') > 0) {
            errors.push(new MunicipalityException('*wajib diisi'));
            return;
          }

          if (err.message.indexOf('facility') > 0) {
            errors.push(new FacilityException('*wajib diisi'));
            return;
          }

          if (err.message.indexOf('sex') > 0) {
            errors.push(new GenderException('*wajib diisi'));
            return;
          }
          return;
        });

        if (errors.length > 0) {
          throw errors;
        }
      }

      const phoneUtil = PhoneNumberUtil.getInstance();
      const parsedMobile = attempt(() => phoneUtil.parse(mobile, 'ID'));

      if (isError(parsedMobile) || !phoneUtil.isValidNumber(parsedMobile)) {
        errors.push(new MobileNumberException('format yang salah'));
        throw errors;
      }

      if (parsedMobile.getCountryCode() !== 62) {
        errors.push(new MobileNumberException('format yang salah'));
        throw errors;
      }

      let subscribersExist;
      if (!form.mobile || subscriberData.mobile) {
        subscribersExist = await findSubscribers({
          programId: 2,
          mobileNumber: String(parsedMobile.getNationalNumber()),
          user_id:
            form?.userId ||
            localStorage.getItem('swrx:activeAccountId') ||
            null,
        }).then((res) => (res.meta.count ? true : false));

        if (subscribersExist) {
          errors.push(
            new MobileNumberException(
              'Nomor ini sudah pernah didaftarkan. Gunakan nomor lain.'
            )
          );
          throw errors;
        }
      }

      if (!error && !subscribersExist) {
        dispatch({
          type: Action.SET_FORM,
          form: {
            ...form,
            ...subscriberData,
            mobile: String(parsedMobile.getNationalNumber()),
          },
        });

        if (form.age === '') {
          Object.assign(form, {
            age: null,
          });
        }

        setErrorMessage(null);
        setConfirmationModal(true);
      }
    } catch (e) {
      if (Array.isArray(e)) {
        let errObj = {};

        e.forEach((err) => {
          if (err instanceof MobileNumberException) {
            errObj = {
              ...errObj,
              mobile: err.message,
            };
            return;
          }

          if (err instanceof AgeException) {
            errObj = {
              ...errObj,
              age: err.message,
            };
            return;
          }

          if (err instanceof MunicipalityException) {
            errObj = {
              ...errObj,
              municipality: err.message,
            };
            return;
          }

          if (err instanceof FacilityException) {
            errObj = {
              ...errObj,
              facility: err.message,
            };
            return;
          }

          if (err instanceof GenderException) {
            errObj = {
              ...errObj,
              gender: err.message,
            };
            return;
          }

          return;
        });

        setErrorMessage(errObj);
      }
    }
  };

  const resetForm = () => {
    dispatch({
      type: Action.SET_FORM,
      form: {
        ...form,
        name: '',
        mobile: '',
        sex: {
          name: '',
          value: null,
        },
        age: '',
        municipality: {
          name: '',
          value: null,
        },
        facility: {
          name: '',
          value: null,
        },
      },
    });
  };

  const confirm = async () => {
    const result = await createReferral(form);
    if (result && !(result instanceof Error)) {
      setForceEnable(false);
      props.history.push(routes.success.path);
    } else {
      setForceEnable(true);
    }
    resetForm();
  };

  const back = () => {
    setForceEnable(true);
    setTermAgreed(false);
    setConfirmationModal(false);
  };

  const agreeToTerms = () => {
    setTermAgreed(!termAgreed);
  };

  const onChangeHandler = (name, value) => {
    if (name === 'facility') {
      setSubscriberData({
        ...subscriberData,
        facilityAddress: facilities.find((f) => f.id === value.value).address,
        facility: value,
      });
    } else {
      setSubscriberData({
        ...subscriberData,
        [name]: value,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="referral-form">
        <h4 className="form-title">
          <b>Formulir Rujukan</b>
        </h4>
        {Form.ReferralForm(
          subscriberData,
          { municipalities, facilities },
          onChangeHandler,
          form,
          forceEnable,
          errorMessage
        )}
        <div className={'button-list'}>
          <Button classes={'primary'} label="Kirim" onClick={() => submit()} />
        </div>
      </div>
      <Modal showModal={confirmationModal}>
        <Form.ConfirmationForm
          subscriberData={subscriberData}
          onAgree={agreeToTerms}
        />
        <div className={'button-list'}>
          <Button
            classes={`primary ${termAgreed ? '' : 'disabled'}`}
            disabled={!termAgreed}
            label="Kirim"
            onClick={() => confirm()}
          />
          <Button
            classes={'secondary'}
            label="Ubah data"
            onClick={() => back()}
          />
        </div>
      </Modal>
      <div className="end-border"></div>
    </React.Fragment>
  );
};
