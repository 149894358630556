import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconError from '@material-ui/icons/Error';
import { useStateContext } from '../../providers/StateContext';

export default function AlertDialog({ isOpen, title, content }) {
  const [{ error }] = useStateContext();
  const [open, setOpen] = React.useState(isOpen || error.isError);

  React.useEffect(() => {
    setOpen(isOpen || error.isError);
  }, [isOpen, error]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {(isOpen || error.isError) && (
            <IconError color="error" fontSize="large" />
          )}
          {title || error.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{content || error.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
