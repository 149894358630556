import React from 'react';
import { Button } from '../components/common';
import { routes } from '../routes';

export const Error = (props) => {
  const back = () => {
    props.history.push(routes.home.path);
  };

  return (
    <React.Fragment>
      <div className="error-container">
        <div className="content">
          <p>
            You do not have sufficient access privilege to access this program.
            <br />
            <br />
            Please contact your program administrator.
          </p>
        </div>
        <div className={'button-list'}>
          <Button
            classes={'primary'}
            label="Kembali / Back"
            onClick={() => back()}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
