export const authFactory = (serviceContainer) => {
  const tokenFromQuery = (location) => {
    if (!location) return;

    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    return token?.replace(/ /g, '+');
  };

  const getCredentials = async (location) => {
    const fromQuery = tokenFromQuery(location);

    if (fromQuery) {
      serviceContainer.tokenStorage.setAccountCredentials({
        refreshToken: fromQuery,
      });

      return serviceContainer.tokenProvider.refreshToken();
    }

    if (!serviceContainer.tokenStorage.isAccountCredentialsValid()) {
      return serviceContainer.tokenProvider.refreshToken();
    }

    if (!serviceContainer.tokenProvider.tokenExpiryTimeoutId) {
      serviceContainer.tokenProvider.setRefreshTimer();
    }

    return serviceContainer.tokenStorage.getAccountCredentials();
  };

  return { getCredentials, tokenFromQuery };
};
