import React, { useState, useEffect } from 'react';
import { Action, useStateContext } from '../providers/StateContext';
import { API } from '../api';
import { Button, Loader } from '../components/common';
import { Table } from '../components/tables';
import { routes } from '../routes';
import { genderTypes, statusTypes } from '../api/types';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Label } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useFnWrapper from '../hooks/useFnWrapper';
dayjs.extend(utc);
registerLocale('id', id);

export const Referrals = (props) => {
  const [{ form, filters }, dispatch] = useStateContext();
  const [referrals, setReferrals] = useState([]);
  const [filter, setFilter] = useState(filters.status || '');
  const [referralsLoading, setReferralsLoading] = useState(true);
  const [paging] = useState({ limit: 100, offset: 0 });
  const [startDate, setStartDate] = useState(
    filters.startDate ||
      new Date(
        dayjs().hour(1).minute(0).second(0).subtract(1, 'month').format()
      )
  );
  const [endDate, setEndDate] = useState(
    filters.endDate || new Date(dayjs().hour(23).minute(59).second(0))
  );

  const [getReferrals] = useFnWrapper(API.getReferrals);
  const [getReferral] = useFnWrapper(API.getReferral);

  useEffect(() => {
    getReferrals({
      referrer: localStorage.getItem('swrx:activeAccountId'),
      startDate,
      endDate,
      status: filter,
      ...paging,
    }).then((res) => {
      setReferrals(res.data);
      setReferralsLoading(false);
    });

    dispatch({
      type: Action.SET_NAME,
      name: 'Daftar rujukan saya',
    });

    dispatch({
      type: Action.SET_FILTERS,
      filters: {
        ...filters,
        startDate,
        endDate,
        status: filter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, paging, dispatch]);

  const filterReferrals = (status) => {
    setFilter(status);
    getReferrals({
      referrer: localStorage.getItem('swrx:activeAccountId'),
      ...(status === '' ? {} : { status }),
      startDate,
      endDate,
      ...paging,
    }).then((res) => {
      setReferrals(res.data);
      dispatch({
        type: Action.SET_FILTERS,
        filters: {
          ...filters,
          status,
        },
      });
    });
  };

  const updateReferral = async (referralId) => {
    const subscriberData = await getReferral(referralId).then(
      (referral) => referral.data.subscriber
    );

    const { referenceCode } = subscriberData;

    dispatch({
      type: Action.SET_FORM,
      form: {
        ...form,
        name: subscriberData.name ?? '',
        mobile: subscriberData.mobileNumber ?? '',
        age: subscriberData.age || null,
        sex: {
          name:
            genderTypes.find((type) => type.value === subscriberData.sex)
              ?.name || '',
          value: subscriberData.sex || '',
        },
        municipality: {
          name: subscriberData.facility?.municipality ?? '',
          value: subscriberData.facility?.municipality ?? '',
        },
        facility: {
          name: subscriberData.facility?.name ?? '',
          value: subscriberData.facility?.name ?? '',
          id: subscriberData.facility?.id ?? '',
          address: subscriberData.facility?.address ?? '',
        },
        status: subscriberData.status,
        symptoms: subscriberData.symptoms,
        referralId: referenceCode,
      },
    });

    props.history.push(routes.update.path);
  };

  return (
    <React.Fragment>
      <div className="referral-table">
        <ScrollContainer className="scroll-container">
          <div className="filter-list">
            <Button
              label="Semua"
              classes={filter === '' ? `active default` : ''}
              onClick={() => filterReferrals('')}
            />
            <Button
              label="Tidak dirujuk"
              classes={
                filter === statusTypes.Screened ? `active ${filter}` : ''
              }
              onClick={() => filterReferrals(statusTypes.Screened)}
            />
            <Button
              label="Dirujuk"
              classes={
                filter === statusTypes.Confirmed ? `active ${filter}` : ''
              }
              onClick={() => filterReferrals(statusTypes.Confirmed)}
            />
          </div>
        </ScrollContainer>
        <div className="date-filter">
          <div className="start">
            <Label>Dari tanggal</Label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale="id"
              maxDate={endDate}
            />
          </div>
          <div className="end">
            <Label>Sampai</Label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              locale="id"
              minDate={startDate}
            />
          </div>
        </div>
        <Loader loading={referralsLoading} />
        <div>{Table.ReferralsTable(referrals, updateReferral)}</div>
      </div>
    </React.Fragment>
  );
};
