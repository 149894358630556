import React, { useEffect, useState } from "react";
import {
  Button as StrapButton,
  Modal as StrapModal,
  Alert as StrapAlert,
  ModalBody,
  Label,
} from "reactstrap";
import SelectSearch from "react-select-search";

export const Button = (props) => {
  return (
    <StrapButton className={`${props.classes} button-default`} {...props}>
      {props.label}
    </StrapButton>
  );
};

export const Select = (props) => {
  return (
    <React.Fragment>
      <div className="select-search-container">
        {props.label && <Label htmlFor={props.name}>{props.label}</Label>}
        {props.options && (
          <SelectSearch
            placeholder={props.placeholder}
            search={props.search}
            onChange={(val) => {
              props.onChangeHandler(props.name, {
                name: props.options.find((option) => option.value === val).name,
                value: val,
              });
            }}
            name={props.name}
            value={props.value}
            id={props.name}
            options={props.options}
            disabled={props.disabled}
          ></SelectSearch>
        )}
        <div className="select-icon">
          <i className="fas fa-caret-down"></i>
        </div>
      </div>
    </React.Fragment>
  );
};

export const Modal = (props) => {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setModal(props.showModal);
  }, [props.showModal]);

  return (
    <StrapModal isOpen={modal} toggle={props.toggle} className={props.classes}>
      <ModalBody>{props.children}</ModalBody>
    </StrapModal>
  );
};

export const Alert = (props) => {
  return (
    <StrapAlert color="danger" className="alert">
      <p>
        <i className="fa fa-exclamation-circle"></i>
        <span>{props.children}</span>
      </p>
    </StrapAlert>
  );
};

export const Loader = (props) => {
  return (
    <React.Fragment>
      {props.loading && <div className="lds-dual-ring"></div>}
    </React.Fragment>
  );
};
