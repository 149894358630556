import axios from 'axios';

/**
 * CoreHttpService -> TokenStorageService
 *    httpClient [call] -> httpInterceptor -> getAccountCredentials()
 * AccessTokenService -> TokenStorageService
 *
 * @typedef {import('../tokens/TokenStorage').TokenStorage} TokenStorage
 */
export class CoreHttp {
  /**
   * Create http service
   *
   * @param {import('axios').AxiosInstance} httpClient - Axios http client
   * @param {TokenStorage} tokenStorage - credentials storage
   */
  constructor(httpClient, tokenStorage) {
    this.httpClient = httpClient;
    this.tokenStorage = tokenStorage;

    this.initializeInterceptors();
  }

  initializeInterceptors() {
    this.httpClient.interceptors.request.use(async (requestConfig) => {
      const accessToken = this.tokenStorage.getAccountCredentials().accessToken;

      if (accessToken) {
        requestConfig.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      return requestConfig;
    });
  }

  /**
   * @param {TokenStorage} tokenStorage - credentials storage
   *
   * @returns {Promise<CoreHttp>}
   */
  static async createCoreHttp(tokenStorage) {
    const configEndpoint = process.env.REACT_APP_API_URL;
    const discoveryUrl = configEndpoint
      .split('/')
      .filter((_slice, index, self) => index < self.length - 1) // remove last element
      .join('/');
    const discoveryResponse = await axios.get(discoveryUrl);
    const httpClient = axios.create({
      baseURL: discoveryResponse.data.urls.coreBaseUrl,
      timeout: 10000,
      headers: {
        'content-type': 'application/json',
      },
    });

    return new CoreHttp(httpClient, tokenStorage);
  }
}
