import moment from 'moment';
import { serviceContainer } from '../services/serviceContainer';

const responseMapper = (response) => response.data;
const responseWrapper = (response) =>
  response.then(responseMapper).catch((err) => {
    if (err.response.statusText === 'Internal Server Error') {
      throw new Error(response.statusText);
    }

    if (err.response.status === 401) {
      const unauthorizedError = new Error(response.statusText);
      unauthorizedError.status = 401;

      throw unauthorizedError;
    }

    throw err.response.data;
  });

const setHeaders = () => ({
  'X-External-User-Id': localStorage.getItem('swrx:activeAccountId'),
  'Content-Type': 'application/json',
});

const defaults = {
  baseUrl: process.env['REACT_APP_API_URL'],
  country: 'Indonesia',
  programId: 2,
  programCode: 'tbps-id',
  platformCode: 'swip',
  headers: setHeaders(),
};

const generateQueryParams = (q) => {
  return `?${Object.keys(q)
    .filter((val) => (q[val] ? true : false))
    .map((key) => `${key}=${q[key]}`)
    .join('&')}`;
};

const checkAuth = (userId) => {
  return responseWrapper(
    serviceContainer.rtHttp.httpClient.post(
      `${defaults.baseUrl}/auth/tb-authorize`,
      { user_id: userId }
    )
  );
};

const getSymptoms = () => {
  const query = `${defaults.baseUrl}/programs/${defaults.programId}/screening`;
  return responseWrapper(serviceContainer.rtHttp.httpClient.get(query));
};

const getFacilities = (district) => {
  const q = generateQueryParams({
    municipality: district,
    country: defaults.country,
  });
  const query = `${defaults.baseUrl}/facilities${q}`;
  return responseWrapper(serviceContainer.rtHttp.httpClient.get(query));
};

const getDistricts = () => {
  const q = generateQueryParams({ country: defaults.country });
  const query = `${defaults.baseUrl}/facilities/districts${q}`;
  return responseWrapper(serviceContainer.rtHttp.httpClient.get(query));
};

const createReferral = (subscriberData) => {
  const query = `${defaults.baseUrl}/programs/refer`;
  const body = {
    first_name: subscriberData.name,
    mobile_number: subscriberData.mobile,
    age: subscriberData.age || null,
    sex: subscriberData.sex.value,
    facility_id: subscriberData.facility.value,
    program_code: defaults.programCode,
    platform_code: defaults.platformCode,
    subscriber_symptoms: subscriberData.symptoms,
  };

  return responseWrapper(serviceContainer.rtHttp.httpClient.post(query, body));
};

const createScreenedReferral = (symptoms) => {
  const query = `${defaults.baseUrl}/programs/save`;
  const body = {
    program_code: defaults.programCode,
    platform_code: defaults.platformCode,
    subscriber_symptoms: symptoms,
  };
  return responseWrapper(serviceContainer.rtHttp.httpClient.post(query, body));
};

const findSubscribers = (subscriberData) => {
  const q = generateQueryParams(subscriberData);
  const query = `${defaults.baseUrl}/subscribers/search${q}`;
  return responseWrapper(serviceContainer.rtHttp.httpClient.get(query));
};

const formatReferralsFilter = (filter) => ({
  ...filter,
  startDate: moment(filter.startDate).utc().format(),
  endDate: moment(filter.endDate).utc().format(),
  platformCode: defaults.platformCode,
  programId: defaults.programId,
  status: Array.isArray(filter.status)
    ? filter.status.map((status) => ['status', status])
    : filter.status
    ? [['status', filter.status]]
    : null,
});

const isValidReferralFilter = ([key, value]) =>
  !(value === undefined || value === null || ['status'].includes(key));

const getReferrals = (filter) => {
  const formattedFilters = formatReferralsFilter(filter);

  return responseWrapper(
    serviceContainer.rtHttp.httpClient.get(`/referrals`, {
      params: new URLSearchParams([
        ...Object.entries(formattedFilters).filter(isValidReferralFilter),
        ...(formattedFilters.status ?? []),
      ]),
    })
  );
};

const getReferral = (referralId) => {
  const query = `${defaults.baseUrl}/referrals/${referralId}`;
  return responseWrapper(serviceContainer.rtHttp.httpClient.get(query));
};

const updateReferral = (referralId, subscriberData) => {
  const query = `${defaults.baseUrl}/referrals/${referralId}`;
  const body = {
    age: subscriberData.age,
    sex: subscriberData.sex,
  };
  return responseWrapper(serviceContainer.rtHttp.httpClient.put(query, body));
};

export const API = {
  getSymptoms,
  getFacilities,
  getDistricts,
  getReferrals,
  getReferral,
  createReferral,
  updateReferral,
  findSubscribers,
  checkAuth,
  createScreenedReferral,
};
