import { Action, useStateContext } from '../providers/StateContext';
import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

export default function useFnWrapper(fn) {
  const [, dispatch] = useStateContext();
  const [_error, setError] = useState();
  useErrorHandler(_error);

  const wrappedFn = async (...args) => {
    try {
      return await fn.apply(null, args);
    } catch (e) {
      const error =
        (e.json && typeof e.json === 'function' && (await e.json())) || {};

      if (e.status && e.status === 401) {
        setError('');
        const { message } = error || { message: e.message };
        dispatch({
          type: Action.SET_ERROR,
          error: {
            isError: true,
            title: 'Unauthorized',
            content: message || e.message || '',
          },
        });
      }
      return e;
    }
  };

  return [wrappedFn];
}
