import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

/**
 * @typedef {import('../tokens/TokenStorage').TokenStorage} TokenStorage
 */
export class RtHttp {
  /**
   * Create http service
   *
   * @param {import('axios').AxiosInstance} httpClient - Axios http client
   * @param {TokenStorage} tokenStorage - credentials storage
   */
  constructor(httpClient, tokenStorage) {
    this.httpClient = httpClient;
    this.tokenStorage = tokenStorage;

    this.initializeInterceptors();
  }

  initializeInterceptors() {
    this.httpClient.interceptors.request.use(async (requestConfig) => {
      const accessToken = this.tokenStorage.getAccountCredentials().accessToken;

      if (accessToken) {
        requestConfig.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      return requestConfig;
    });
  }

  /**
   * @param {TokenStorage} tokenStorage - credentials storage
   *
   * @returns {Promise<RtHttp>}
   */
  static async createRtHttp(
    tokenStorage,
    httpClient = axios.create({
      baseURL,
      timeout: 10000,
      headers: {
        'content-type': 'application/json',
      },
    })
  ) {
    return new RtHttp(httpClient, tokenStorage);
  }
}
