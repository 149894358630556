import Joi from 'joi';

export const updateReferralSchema = Joi.object({
  age: Joi.number().min(1).max(99).required(),
  sex: Joi.string().required(),
});

export const referralSchema = Joi.object({
  mobile_number: Joi.string().required(),
  age: Joi.number().min(1).max(99).required(),
  sex: Joi.string().required(),
  municipality: Joi.string().required(),
  facility: Joi.string().required(),
});
