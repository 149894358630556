import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import './assets/styles/index.scss';
import { initializeServices } from './services/serviceContainer';

initializeServices().then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
  serviceWorker.unregister();
});
