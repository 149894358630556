import React, { createContext, useReducer, useContext } from 'react';

const ErrorInit = {
  isError: false,
  title: 'Error',
  content: 'Internal Error, Please try again later',
};

const SET_FORM = 'SET_FORM';
const SET_SCREENING = 'SET_SCREENING';
const SET_NAME = 'SET_NAME';
const SET_ALLOW_ACCESS = 'SET_ALLOW_ACCESS';
const SET_FILTERS = 'SET_FILTERS';
const SET_ERROR = 'SET_ERROR';

export const Action = {
  SET_FORM,
  SET_SCREENING,
  SET_NAME,
  SET_ALLOW_ACCESS,
  SET_FILTERS,
  SET_ERROR,
};

const StateContext = createContext();

export const StateContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case SET_FORM:
        return {
          ...state,
          form: { ...action.form },
        };
      case SET_SCREENING:
        return {
          ...state,
          screening: { ...action.screening },
        };
      case SET_NAME:
        return {
          ...state,
          name: action.name,
        };
      case SET_ALLOW_ACCESS:
        return {
          ...state,
          isAuthorized: action.isAuthorized,
        };
      case SET_FILTERS:
        return {
          ...state,
          filters: action.filters,
        };
      case SET_ERROR:
        return {
          ...state,
          error: action.error,
        };
      default:
        return state;
    }
  };
  return (
    <StateContext.Provider
      value={useReducer(reducer, {
        form: {},
        screening: {},
        isAuthorized: false,
        filters: {},
        error: ErrorInit,
      })}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
