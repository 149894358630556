export const genderTypes = [
  { value: 'male', name: 'Pria' },
  { value: 'female', name: 'Wanita' },
  { value: 'other', name: 'Lainnya' },
];

export const statusTypes = {
  Incomplete: 'incomplete',
  Pending: 'pending',
  Declined: 'declined',
  Confirmed: 'confirmed',
  Screened: 'screened',
};

export const translateStatus = (status) => {
  switch (status) {
    case statusTypes.Confirmed:
      return 'Dirujuk';
    case statusTypes.Screened:
      return 'Tidak dirujuk';
    default:
      return '';
  }
};
