import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorIcon from '@material-ui/icons/Error';
import MUIButton from '@material-ui/core/Button';

const RetryButton = (props) => {
  const { classes, ...compProps } = props;
  return (
    <MUIButton className={`${classes} button-default`} {...compProps}>
      {props.label}
    </MUIButton>
  );
};

export const ErrorFallback = ({
  error,
  componentStack,
  resetErrorBoundary,
}) => {
  return (
    <>
      <div role="alert" className="error-fallback">
        <div>
          <ErrorIcon className="icon-error" />
          <p className="error-title">Something went wrong.</p>
        </div>
      </div>
      <div className={'error-button-list'}>
        <RetryButton
          classes={'primary'}
          label="Try again"
          onClick={resetErrorBoundary}
        />
      </div>
    </>
  );
};

export const RouteWithErrorBoundary = (props) => {
  return (
    <ErrorBoundary
      key={props.location?.pathname}
      FallbackComponent={ErrorFallback}
    >
      <Route {...props} />
    </ErrorBoundary>
  );
};
