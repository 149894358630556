const PatientSuccessIcon = {
  alt: "patient-success-icon",
  src: require("./patient-submit-success-icon.png"),
};

const CloseIcon = {
  alt: "close",
  src: require("./close-icon.png"),
};

const Banner = {
  alt: "tbps-banner",
  src: require("./banner.jpg"),
};

const Steps = {
  alt: "rt-steps",
  src: require("./steps.jpg"),
};

const Check = {
  alt: "rt-check",
  src: require("./tick.png"),
};

const DataSave = {
  alt: "rt-save",
  src: require("./data_save.png"),
};

const ScreeningDone = {
  alt: "rt-screen-done",
  src: require("./patient_done.png"),
};

const CalendarIcon = {
  alt: "calendar-icon",
  src: require("./calendar.jpg"),
};

export const Image = {
  PatientSuccessIcon,
  CloseIcon,
  Banner,
  Steps,
  Check,
  DataSave,
  ScreeningDone,
  CalendarIcon,
};
