import React from 'react';
import { Action, useStateContext } from '../../providers/StateContext';

export const AppHeader = (props) => {
  const [{ name, form }, dispatch] = useStateContext();

  const back = () => {
    if (props.history.location.pathname === '/') {
      return (window.location = 'swrx://exit');
    }

    if (
      props.history.location.pathname === '/refer/screening' ||
      props.history.location.pathname === '/refer/end' ||
      props.history.location.pathname === '/refer/success'
    ) {
      return props.history.push('/');
    }

    if (props.history.location.pathname === '/refer/form') {
      dispatch({
        type: Action.SET_FORM,
        form: {
          ...form,
          name: '',
          mobile: '',
          sex: {
            name: '',
            value: null,
          },
          age: '',
          municipality: {
            name: '',
            value: null,
          },
          facility: {
            name: '',
            value: null,
          },
        },
      });
      return props.history.goBack();
    }

    props.history.goBack();
  };

  return (
    <div className="app-header">
      <button onClick={() => back()} className="app-back">
        <i className="fas fa-chevron-left"></i>
      </button>
      <h2 className="app-title">{name || ''}</h2>
    </div>
  );
};
