import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { history, routes } from './routes';
import { StateContextProvider } from './providers/StateContext';
import { AppHeader } from './components/common/AppHeader';
import AlertDialog from './components/common/AlertDialog';
import { RouteWithErrorBoundary } from './components/error/RouteWithErrorBoundary';

export const App = () => {
  return (
    <StateContextProvider>
      <AppHeader history={history}></AppHeader>
      <AlertDialog />
      <div className="app-main">
        <Router history={history}>
          <Switch>
            {Object.keys(routes).map((route) => (
              <RouteWithErrorBoundary
                key={routes[route].name.toLowerCase()}
                {...routes[route]}
              />
            ))}
          </Switch>
        </Router>
      </div>
    </StateContextProvider>
  );
};
