/* eslint-disable no-throw-literal */
import React, { useEffect, useState } from 'react';
import { statusTypes } from '../api/types';
import { Form } from '../components/forms';
import { Action, useStateContext } from '../providers/StateContext';

export const UpdateReferral = (props) => {
  const [{ form }, dispatch] = useStateContext();

  const [errorMessage] = useState(null);
  const [subscriberData] = useState({
    mobile: form.mobile ? `0${form.mobile}` : '',
    sex: form.sex || {
      name: '',
      value: null,
    },
    age: form.age || '',
    municipality: form.municipality || {
      name: '',
      value: null,
    },
    facility: form.facility || {
      name: '',
      value: null,
    },
    referralId: form.referralId,
  });
  const [newData, setNewData] = useState({
    sex: {
      name: '',
      value: null,
    },
    age: '',
  });

  useEffect(() => {
    dispatch({
      type: Action.SET_NAME,
      name: `Klien #${form.referralId}`,
    });
  }, [dispatch, form.referralId]);

  const onChangeHandler = (name, value) => {
    setNewData({
      ...newData,
      [name]: value,
    });
  };

  const referralMsg = () => {
    switch (form.status) {
      case statusTypes.Screened:
        return `Klien Anda menunjukkan  ${
          form.symptoms?.length || 0
        } dari 10 gejala
        dan klien tidak dirujuk.`;
      default:
        return `Klien Anda menunjukkan  ${
          form.symptoms?.length || 0
        } dari 10 gejala
        dan klien telah dirujuk.`;
    }
  };

  return (
    <React.Fragment>
      <div className="referral-update">
        <div className={`symptoms-banner ${form.status}`}>
          <p>{referralMsg()}</p>
        </div>
        <div className="referral-form update">
          {form.status !== statusTypes.Screened && (
            <Form.UpdateForm
              newData={newData}
              subscriberData={subscriberData}
              onChangeHandler={onChangeHandler}
              errorMessages={errorMessage}
            />
          )}
          <div className="selected-symptoms">
            {form.symptoms?.map((symptom) => (
              <div
                key={`symptom-list-${symptom.id}`}
                className="symptom selected"
              >
                <img
                  className="symptom-icon"
                  src={symptom.thumbnail_url}
                  alt="tb-symptom"
                />
                <p>{symptom.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="end-border"></div>
      </div>
    </React.Fragment>
  );
};
