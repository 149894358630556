import React from 'react';
import { Button } from '../components/common';
import { Image } from '../assets/images';

export const Success = (props) => {
  const done = () => (window.location = 'swrx://exit');
  return (
    <>
      <div className="success-alert">
        <div>
          <img src={Image.DataSave.src} alt={Image.DataSave.alt} />
          <p className="success-title">Terima kasih</p>
          <p className="success-content">
            Kami akan segera mengirimkan SMS konfirmasi ke nomor HP yang telah
            didaftarkan.
          </p>
        </div>
        <div className={'button-list'}>
          <Button classes={'primary'} label="Tutup" onClick={() => done()} />
        </div>
      </div>
      <div className="end-border"></div>
    </>
  );
};
