import { Home } from './Home';
import { Screen } from './Screen';
import { Refer } from './Refer';
import { Referrals } from './Referrals';
import { Error } from './Error';
import { Success } from './Success';
import { ScreenEnd } from './ScreenEnd';
import { UpdateReferral } from './UpdateReferral';
import { Auth } from './Auth';

export const Containers = {
  Home,
  Screen,
  Refer,
  Referrals,
  Error,
  Success,
  ScreenEnd,
  UpdateReferral,
  Auth,
};
