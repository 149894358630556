import React from 'react';
import { Table as StrapTable } from 'reactstrap';
import { translateStatus } from '../../api/types';
import * as moment from 'moment';

const ReferralsTable = (data, updateReferral) => {
  return (
    <StrapTable>
      <tbody>
        {data && data.length ? (
          data.map((referral, idx) => {
            const { id, subscriber, createdAt } = referral;
            const { referenceCode, status } = subscriber;
            return (
              <tr
                key={`referral-${id}`}
                className="referral-item"
                onClick={() => updateReferral(id)}
              >
                <td>
                  <p className="referral-index">{idx + 1}</p>
                </td>
                <td>
                  <div className="referral-details">
                    <p className="line-1">
                      {moment(createdAt).format('MMMM DD, YYYY')}
                    </p>
                    <p className="line-2">
                      {moment(createdAt).format('h:mm:ss A')}
                    </p>
                    <p className="line-3">#{referenceCode}</p>
                  </div>
                </td>
                <td>
                  <div className="referral-status">
                    <p className={`status status-${status}`}>
                      <span>{translateStatus(status)}</span>
                    </p>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <React.Fragment>
            <tr className="referral-item no-result">
              <td>
                <p>Tidak ada data rujukan.</p>
              </td>
            </tr>
          </React.Fragment>
        )}
      </tbody>
    </StrapTable>
  );
};

export const Table = {
  ReferralsTable,
};
